import { Button, Divider, Drawer, message, Spin } from "antd"
import { useGetCertificate } from "../network/useMedia"
import { useGetPerson, usePersonSignatureMutation } from "../network/usePerson"
import SignatureCanvas from "react-signature-canvas"
import { useRef, useState } from "react"

export const CertificateDrawerWithFetch = ({ visible, enrolment, onClose, enrolmentUid }) => {
  const [hasSigned, setHasSigned] = useState(() => {
    if (!enrolment?.publication?.certificationRequiresStudentSignature) {
      return true
    }
    if (enrolment?.completionAcknowledgedAt) {
      return true
    }
    return false
  })

  const certificateQuery = useGetCertificate({ enrolmentUid: visible && hasSigned ? enrolmentUid : false })
  const personQuery = useGetPerson({ authenticated: true })
  const signatureMutation = usePersonSignatureMutation()
  const signatureRef = useRef()

  const handleSave = async (defaultSignature = false) => {
    const dataURL = signatureRef.current.getTrimmedCanvas().toDataURL("image/png")

    // Make a network post request to acknowledge completion
    await signatureMutation.mutateAsync(
      { data: { enrolmentUid, signatureImageUrl: defaultSignature ? personQuery.data.signatureImageUrl : dataURL } },
      {
        onError: (error) => {
          message.error(`Failed to save signature: ${error.response?.data?.message}`)
        },
        onSuccess: () => {
          message.success("Signature saved and completion acknowledged.")
          setHasSigned(true)
        },
      }
    )
  }

  return (
    <Drawer
      width={window.innerWidth > 900 ? 800 : window.innerWidth}
      visible={visible}
      onClose={(e) => {
        e.preventDefault()
        onClose()
      }}
      title="Certificate"
      placement="right"
    >
      {enrolment?.publication?.certificationRequiresStudentSignature ? (
        <>
          {hasSigned ? (
            <>
              <h2>Your Signed Completion Certificate</h2>

              {certificateQuery.isLoading ? (
                <Spin />
              ) : (
                <embed
                  title="Completion Certificate"
                  style={{ width: "100%", height: "100%" }}
                  src={"data:application/pdf;base64," + certificateQuery.data}
                  alt="certificate base 64 url"
                />
              )}
            </>
          ) : (
            <>
              {personQuery?.data?.signatureImageUrl ? (
                <>
                  <h2>Use Existing Signature</h2>
                  <p>You have an existing signature. Click below to use it.</p>
                  <Button disabled={signatureMutation.isLoading} onClick={() => handleSave(true)}>
                    Use Existing Signature
                  </Button>
                  <Divider orientation="left" plain>
                    Or override with a new signature
                  </Divider>
                  <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{
                      width: window.innerWidth > 900 ? 800 - 48 : window.innerWidth - 48,
                      style: { border: "1px solid grey", borderRadius: 4 },
                      height: 200,
                      className: "signature-canvas",
                    }}
                  />
                  <Button disabled={signatureMutation.isLoading} onClick={() => handleSave(false)}>
                    Save New Signature
                  </Button>
                </>
              ) : (
                <>
                  <h2>Sign Your Certificate</h2>
                  <p>Please sign below to acknowledge your completion.</p>
                  <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{
                      width: window.innerWidth > 900 ? 800 - 48 : window.innerWidth - 48,
                      style: { border: "1px solid grey", borderRadius: 4 },
                      height: 200,
                      className: "signature-canvas",
                    }}
                  />
                  <Button disabled={signatureMutation.isLoading} onClick={() => handleSave(false)}>
                    Save Signature
                  </Button>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <h2>Your Completion Certificate</h2>
          <p>Your certificate is displayed below.</p>

          {certificateQuery.isLoading ? (
            <Spin />
          ) : (
            <embed
              title="Completion Certificate"
              style={{ width: "100%", height: "100%" }}
              src={"data:application/pdf;base64," + certificateQuery.data}
              alt="certificate base 64 url"
            />
          )}
        </>
      )}
    </Drawer>
  )
}
